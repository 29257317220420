import React from "react";
import HeaderComponent from "../../components/HeaderComponent/HeaderComponent";

function HomePage(props) {
    return (
        <>
        
            <HeaderComponent />
        </>
    );
}

export default HomePage;
